import React from 'react';
import { Progress, Form } from 'antd';

import { getStrokeColor } from '../../../look';

import { withMyTeamsResiliencePulseCheck } from '../../containers/DashboardOperations';

const FormItem = Form.Item;

const RenderMyTeamsResiliencePulseCheck = ({ loading, myTeamsResiliencePulseCheck }) => (
  <FormItem labelCol={{ span: 24 }} wrapperCol={{ span: 24, offset: 1 }} label={'User’s Team’s Resilience Pulse Check'}>
    <Progress
      format={percent => percent / 10 + '%'}
      percent={Math.round((myTeamsResiliencePulseCheck && JSON.parse(myTeamsResiliencePulseCheck)?.overall?.toFixed(2) * 10))  || 0}
      strokeColor={
        myTeamsResiliencePulseCheck && getStrokeColor(JSON.parse(myTeamsResiliencePulseCheck)?.overall?.toFixed(2) || 0)
      }
    />
  </FormItem>
);

export default withMyTeamsResiliencePulseCheck(RenderMyTeamsResiliencePulseCheck);
