import React from 'react';
import { Progress, Form } from 'antd';

import { getStrokeColor } from '../../../look';

import { withMyOrganizationResiliencePulseCheck } from '../../containers/DashboardOperations';

const FormItem = Form.Item;

const RenderMyOrganizationResiliencePulseCheck = ({ loading, myOrganizationResiliencePulseCheck }) => (
  <FormItem labelCol={{ span: 24 }} wrapperCol={{ span: 24, offset: 1 }} label={'Organization’s Resilience Pulse Check​'}>
    <Progress
      format={percent => percent / 10 + '%'}
      percent={
        Math.round((myOrganizationResiliencePulseCheck && JSON.parse(myOrganizationResiliencePulseCheck)?.overall?.toFixed(2) * 10)) || 0
      }
      strokeColor={getStrokeColor(
        (myOrganizationResiliencePulseCheck && JSON.parse(myOrganizationResiliencePulseCheck)?.overall?.toFixed(2)) || 0
      )}
    />
  </FormItem>
);

export default withMyOrganizationResiliencePulseCheck(RenderMyOrganizationResiliencePulseCheck);
