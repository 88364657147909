import React, { useState, useEffect, useRef } from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import { Space, Col, Row, Empty, Button, Carousel, Progress, Popover, Steps } from 'antd';
import { CaretRightOutlined, CheckOutlined } from '@ant-design/icons';
// import help_icon from '../../assets/help-text.svg'
import {
    ReactMarkdown,
    RadioField,
    MLCheckBoxField,
    TextField,
    MLSelectField,
    RangeField,
    SelectField,
    displayDataCheck,
    ColorField,
    InputValuesObject,
    DatePickerField,
    interpolate,
    getIntFromString,
    withMarkdownFieldValue
} from '../../look';
import SurveyCheckBoxField from './survey-checkbox-filed';
import SurveyColorField from './survey-color-filed';
import SurveyRadioField from './survey-radio-button-filed';
import SurveyRangeField from './survey-range-filed';
import SurveyTextField from './survey-text-field';
import SurveySelectField from './survey-select-filed';
import SurveyTimePicker from './survey-time-field';
import { withApollo } from '@apollo/client/react/hoc';
// import { compose } from 'modules/core';
import { compose } from '../../core';

// import { FILTER_SURVEY_CHOICE } from '../../diversity-survey/graphql/filterSurveyChoice.gql'
// import ProgressCounterBtn from 'modules/survey/components/ProgressCounterBtnNative';
// import ProgressCounterBtnNative from 'modules/survey/components/ProgressCounterBtnNative';
import SurveyDatePickerField from './survey-datepicker-field';
import RenderUploadWithCrop from './survey-file-upload';
import SurveyMLSelectField from './surveyMLRadioButtom';
import CKEditorFieldSurvey from './survey-ck-editio-filed';
import GenericMartx from './GenericMatrix';
export const TIME_LIMIT = 5;

const emailRules = { type: 'email', message: 'Not a vaild email' };

export function getTypeFromQuestion( question ) {
    return question?.choiceSet?.edges[ 0 ]?.node?.inputType;
}
const Step = Steps.Step;
const GenericSurveyView = props => {
    const {
        questionList,
        clickedQuestions,
        setClickedQuestions,
        matrix,
        sectionLabel,
        setCurrentSection,
        matrixLabel,
        setQuesAnswered,
        prevPressed,
        matrixGrpSet,
        quesAnswered,
        form,
        nextStep,
        srNo,
        ref,
        idx,
        currentPage,
        timeoutFunc,
        closetimer,
        currentIsMatrix,
        currentSection,
        showOk,
        setShowOk,
        currentMatrixQuestion,
        survey,
        client,
        formValues,
        selectedId,
    } = props;
    const [showInput,setShowInput]=React.useState(false)
    const [paneList, setPaneList] = React.useState([])
    const values = form.getFieldsValue( true );
    const [isPaneVisible, setIsPaneVisible] = React.useState(false)
    const quesPaneRef = useRef(null);
    console.log('question pane ref for testing and tracking the question === ::', idx,srNo,currentSection)
    const getRangeValues = ( choiceSet, type ) => {
        let defaultValue = 0,
            marks = {},
            step = 1;
        const value = choiceSet && choiceSet[ 0 ]?.value?.split( ':' ) || [];
        // min:max:jump:default
        if ( type === 'RANGE' ) {
            marks[ value[ 0 ] ] = value[ 0 ];
            marks[ value[ 1 ] ] = value[ 1 ];
            step = value[ 2 ] === '0' ? '1' : value[ 2 ];
            defaultValue = value[ 3 ];
        }
        return { step, marks, defaultValue, min: Number( value[ 0 ] ), max: Number( value[ 1 ] ) };
    };

    const handleQuestionClick = (e) =>  {
        const dataKeyExtracted = e.currentTarget.getAttribute('dataKey')

        setCurrentSection(dataKeyExtracted)
        setClickedQuestions((prevClickedQuestions) => {
            console.log('original clickedQuestions array:', prevClickedQuestions,typeof(dataKeyExtracted),dataKeyExtracted.toString());

            const updatedClickedQuestions = [...prevClickedQuestions, dataKeyExtracted.toString()];
            console.log('Updated clickedQuestions array:', updatedClickedQuestions);
            return updatedClickedQuestions;  // Return the updated array as the new state
          });
          
    }

    useEffect(()=>{
        let isQuesPresent = clickedQuestions?.includes((idx+1).toString())
        setIsPaneVisible(isQuesPresent)
        
        console.log('is the srno present or clicked', clickedQuestions,  isQuesPresent,idx+1,srNo);

    },[clickedQuestions])

    const RenderQuestion = ( question, index ) => {
        const [ colorLoading, setColorLoading ] = React.useState( false );
        const [ load, setLoad ] = useState( false );
        const { id, sequence, questionText, required, helpText } = question;

        const [ choiceSet, setchoiceSet ] = React.useState( question?.choiceSet )
        
        console.log('gueg8g83ge',question,questionText)

        const type = getTypeFromQuestion( question );
        console.log("type",type,question)
        const [ reset, setReset ] = useState( false );
        const [open, setOpen] = useState(false);
        const obj = {};
        const keyArray = Object.keys( values );
        keyArray?.length > 0 &&
            keyArray.map( key => {
                obj[ getIntFromString( key ) ] = values[ key ];
            } );

        // const onSelectFeildSearch = async ( search, que ) => {
        //     let id = survey?.id;
        //     const { data } = await client.query( {
        //         query: FILTER_SURVEY_CHOICE,
        //         variables: { id: id, label_Icontains: search },
        //         // fetchPolicy: 'network-only'
        //     } );
        //     if ( data?.Options ) {
        //         let qa = data?.Options.groupSet?.edges.map( item => item?.node?.questionSet?.edges )
        //         qa = [].concat.apply( [], qa )
        //         let current = qa.find( item => item?.node?.id === que?.id )
        //         if ( current ) {
        //             setchoiceSet( current?.node?.choiceSet )
        //         }
        //     }
        // }
        const focusOnTargetQuestion = () => {
            if (quesPaneRef.current) {
                quesPaneRef.current.scrollIntoView({
                    behavior: 'smooth', // Optional: Adds smooth scrolling behavior
                    block: 'center', // Optional: Scrolls to the top of the element
                  });
            }
          };

          useEffect(()=>{
            if(quesPaneRef.current && currentPage ){
                
                // focusOnTargetQuestion()
            }
        },[currentPage, currentSection])



        

        const label = (
            <div style={{display:'flex',flexDirection:'row',alignItems:'flex-start',gap:'0px'}}>
                <h4>{(idx+1)}.</h4>
                <ReactMarkdown style={{ marginLeft: '4px' }}>{interpolate( questionText?.replace(/\{\d+}/g, ''), obj )}</ReactMarkdown>
            </div>
        );
        const renderLabel = (
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", gap: "10px",width:"100%" ,paddingTop:"10px"}}>
                {required&&<span style={{color:"#DE4A4A"}}>*</span>}
                {label}
            </div>
        );

        const rules = [ { required: true, message: 'An answer is required.' } ];

        const { step, marks, defaultValue, min, max } = getRangeValues(
            choiceSet?.edges?.map( ( { node } ) => node ),
            type
        );

        const handleChange = (doNext, serial) => {
            focusOnTargetQuestion()

            if ( doNext ) {
                if ( showOk ) {
                    // setShowOk( false,id );
                    setShowOk( true,id );
                    setReset( true );
                    // closetimer();
                    setCurrentSection(currentSection + 1)
                    goToNextQuestion((idx+1) + 1)

                    currentSection >= quesAnswered && setQuesAnswered(currentSection + 1)
                } else {
                    setShowOk( true,id );
                    // timeoutFunc( srNo );
                    setCurrentSection(currentSection + 1)
                    goToNextQuestion((idx+1) + 1)

                    currentSection >= quesAnswered && setQuesAnswered(currentSection + 1)
                    // focusOnTargetQuestion()
                }
            } else {
                setShowOk( true );
            }
        };
        const OperandValues=(id)=>{
            if(formValues?.length>0){
                let filer =formValues?.find(item=>getIntFromString(item.questionId) === getIntFromString(id))
                if(filer){
                    return true
                }
                else{
                    return false
                }
            }
        }
        const enableAnsweredQues = (selectedId) => {
            if (selectedId <= quesAnswered){
                let newPaneList = paneList.push(selectedId)
                setPaneList(newPaneList)
            }
            else{
            }
        }
       const OperandSelectedValues = (id)=>{
        console.log('sethsowisheretobeseen ',selectedId, quesAnswered)
            if (id <= quesAnswered){
                setShowInput(true)
            }
            else{
                setShowInput(false)
            }
        
        if(formValues?.length>0){
            let filer =formValues?.find(item=>getIntFromString(item.questionId)===getIntFromString(id))
            console.log("formValues",filer)
            if(filer){

                setShowInput(true)
            }
            else{
                setShowInput(false)
            }
        }
        }

        const goToNextQuestion = (quesSerial) =>  {
            const dataKeyExtracted = quesSerial

            setCurrentSection(dataKeyExtracted)
            setClickedQuestions((prevClickedQuestions) => {
                console.log('original clickedQuestions array:', prevClickedQuestions,typeof(dataKeyExtracted),dataKeyExtracted.toString());

                const updatedClickedQuestions = [...prevClickedQuestions, dataKeyExtracted.toString()];
                console.log('Updated clickedQuestions array:', updatedClickedQuestions);
                return updatedClickedQuestions;  // Return the updated array as the new state
              });
        }
        const helpContent =(text)=>{
            return (
                <div style={{maxWidth:"400px",width:"100%"}}>
                    <p className='generic-survey-help-text'><ReactMarkdown>{text}</ReactMarkdown></p>
                    <button className='generic-survey-help-text-btn' onClick={()=>setOpen(false)}>Got it</button>
                </div>
            )
        }

        console.log('sethsowisheretobe3rseen ', isPaneVisible)

        const CKEditorFieldName = 'surveytext';
        return true && (matrixGrpSet?.quesLabel || matrixGrpSet?.label)?.length > 2 ? (
            <div onClick={()=>showInput?(() => { enableAnsweredQues(id); setShowInput(false); })():(OperandSelectedValues(id),selectedId(id)) }  className="generic-survey-question-container"  style={{background: OperandValues(id)?"rgba(236, 241, 244, 1)":"rgba(244, 249, 253, 1)"}} key={index} visible={currentSection === srNo}  >
                <div  onClick={(e)=> handleQuestionClick(e)} dataKey={(idx+1)} style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", gap: "10px" }}  >
                    <h4  className='generic-survey-question' style={{ cursor: "pointer",display:'flex',flexDirection:"row",alignItems:"center",gap:"10px" }}>{renderLabel}</h4>
                    {helpText && <Popover visible={open}  trigger="click" overlayClassName="generic-survey-help-text-popover" placement="topRight"content={helpContent(helpText)}><img src={"https://res.cloudinary.com/mapp-brainayan-app/image/upload/v1707735172/WorkplaceResilienceProduction/Survey/Images/help-text_isixin.svg"} alt='' style={{ cursor: "pointer", width: "25px" }} onClick={()=>setOpen(true)}/>
                    </Popover>}
                </div>
                {( isPaneVisible || idx === 0) && (
                    <div ref={quesPaneRef} qDataKey={(idx+1)} style={{marginTop:"10px"}}>
                        {type === InputValuesObject.checkbox && (
                            <SurveyCheckBoxField
                                name={id}
                                //   label={renderLabel}
                                rules={required ? rules : []}
                                choices={choiceSet.edges.map( ( { node } ) => node ).map( c => ( { label: c.label, value: c.value } ) )}
                                onChange={e => e && handleChange( false,srNo )}
                            />
                        )}
                        {type === InputValuesObject.color && (
                            <SurveyColorField
                                name={id}
                                //   label={renderLabel}
                                rules={required ? rules : []}
                                choices={choiceSet.edges.map( ( { node } ) => node ).map( c => ( { label: c.label, value: c.value } ) )}
                                onChange={e => {
                                    e && form.setFieldsValue( _.set( form.getFieldsValue( true ), [ id ], e ) ) && handleChange( false,srNo )
                                }}
                                value={form.getFieldValue( id )}
                            />
                        )}
                        {type === InputValuesObject.date && (
                            <SurveyDatePickerField
                                margin={false}
                                name={id}
                                //   label={label}
                                offset={3}
                                format="YYYY-MM-DD"
                                rules={[ { required, message: 'Please select a date' } ]}
                                enabledisabledDate={false}
                                onChange={e => e && handleChange( true,srNo )}
                            />
                        )}
                        {type === InputValuesObject.datetime && (
                            <SurveyDatePickerField
                                margin={false}
                                name={id}
                                // label={label}
                                offset={3}
                                showTime={{ format: 'HH:mm' }}
                                format="YYYY-MM-DD HH:mm"
                                rules={[ { required, message: 'Please select a date and time' } ]}
                                enabledisabledDate={false}
                                onChange={e => e && handleChange( true,srNo )}
                            // defaultValue={moment('2015-06-06', 'YYY-MM-DD')}
                            />
                        )}
                        {type === InputValuesObject.month && (
                            <SurveyDatePickerField
                                margin={false}
                                name={id}
                                //   label={label}
                                offset={3}
                                rules={[ { required, message: 'Please select a month' } ]}
                                enabledisabledDate={false}
                                picker="month"
                                onChange={e => e && handleChange( true,srNo )}
                            // defaultValue={moment('2015-06-06', 'YYY-MM-DD')}
                            />
                        )}
                        {type === InputValuesObject.time && (
                            <SurveyTimePicker
                                margin={false}
                                name={id}
                                //   label={label}
                                offset={3}
                                rules={[ { required, message: 'Please select a month' } ]}
                                enabledisabledDate={false}
                                type="time"
                                onChange={e => e && handleChange( true,srNo )}
                            // defaultValue={moment('2015-06-06', 'YYY-MM-DD')}
                            />
                        )}
                        {type === InputValuesObject.week && (
                            <SurveyDatePickerField
                                margin={false}
                                name={id}
                                //   label={label}
                                offset={3}
                                rules={[ { required, message: 'Please select a month' } ]}
                                enabledisabledDate={false}
                                picker="week"
                                onChange={e => e && handleChange( true,srNo )}
                            // defaultValue={moment('2015-06-06', 'YYY-MM-DD')}
                            />
                        )}
                        {type === InputValuesObject.radio && (
                            <SurveyRadioField
                                name={id}
                                //   label={renderLabel}
                                choices={choiceSet.edges.map( ( { node } ) => node ).map( c => ( { label: c.label, value: c.value } ) )}
                                rules={required ? rules : []}
                                onChange={e => e?.target?.value && handleChange( true,srNo )}
                            />
                        )}
                        {type === InputValuesObject.range && (
                            <SurveyRangeField
                                name={id}
                                //   label={
                                //     <div style={{ minWidth: '25vw', maxWidth: 'calc(100% - 30px)' }}>
                                //       {label}
                                //       <ReactMarkdown style={{ marginLeft: '3vw' }}>{helpText}</ReactMarkdown>
                                //     </div>
                                //   }
                                rules={required ? rules : []}
                                marks={marks}
                                step={step}
                                defaultValue={defaultValue}
                                min={min}
                                max={max}
                                trackStyle={{ backgroundColor: '#B0CB1F' }}
                                handleStyle={{ backgroundColor: '#B0CB1F' }}
                                onAfterChange={e => e && handleChange( true,srNo )}
                                onKeyDown={e => e && handleChange( true,srNo )}
                            />
                        )}
                        {type === InputValuesObject.email && (
                            <SurveyTextField
                                name={id}
                                // label={renderLabel}
                                rules={required ? rules.map( r => ( { ...r, ...emailRules } ) ) : [ { ...emailRules } ]}
                                // rules={[{ type: 'email' }]}
                                offset={2}
                                onChange={e => e.target.value && handleChange(false, srNo)}
                                onBlur={e => e?.target?.value && handleChange( false, srNo )}
                            />
                        )}
                        {type === InputValuesObject.file && (
                            <RenderUploadWithCrop
                                setUrl={e =>{e&&handleChange( true, srNo ) ;form.setFieldsValue( _.set( form.getFieldsValue( true ), id, e ) )}}
                                setLoad={setLoad}
                                offset={2}
                                name={id}
                            //   label={renderLabel}
                            />
                        )}
                        <Row>
                            <Col lg={24} xs={20}>
                                {type === InputValuesObject.text && (
                                    <SurveyTextField
                                        name={id}
                                        //   label={renderLabel}
                                        rules={required ? rules : []}
                                        offset={2}
                                        onChange={e => e?.target?.value && handleChange(false, srNo)}
                                        // onClick={e => e && handleChange(true)}
                                        onKeyDown={e => e?.key === 'Enter' && e?.target?.value && handleChange(true, srNo)}

                                        onBlur={e => e?.target?.value && handleChange( true, srNo )}
                                    />
                                )}
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={24} xs={20}>
                                {type === InputValuesObject.url && (
                                    <SurveyTextField
                                        name={id}
                                        //   label={renderLabel}
                                        type="url"
                                        rules={required ? rules : []}
                                        offset={2}
                                        onChange={e => e && handleChange(false)}
                                        onBlur={e => e?.target?.value && handleChange( false, srNo )}
                                    />
                                )}
                            </Col>
                        </Row>
                        {type === InputValuesObject.number && (
                            <SurveyTextField
                                name={id}
                                //   label={renderLabel}
                                rules={required ? rules : []}
                                type="number"
                                offset={2}
                                onChange={e => e && handleChange(false)}
                                onBlur={e => e?.target?.value && handleChange( false, srNo )}
                            />
                        )}
                        {type === InputValuesObject.password && (
                           <SurveyTextField
                                name={id}
                                //   label={renderLabel}
                                rules={required ? rules : []}
                                type="password"
                                offset={2}
                                onChange={e => e && handleChange(false)}
                                onBlur={e => e?.target?.value && handleChange( false, srNo )}
                           />
                        )}
                        {type === InputValuesObject.select && (
                            <Row>
                                <Col lg={24} xs={20}>
                                    <SurveySelectField
                                        showSearch
                                        // onSearch={( e ) => { onSelectFeildSearch( e, question ) }}
                                        otherOption={true}
                                        name={id}
                                        offset={2}
                                        //   label={renderLabel}
                                        rules={required ? rules : []}
                                        choices={choiceSet.edges.map( ( { node } ) => node ).map( c => ( { label: c.label, value: c.value } ) )}
                                        onSelectChange={e => e && handleChange( true, srNo )}
                                        onClick={e => e?.target?.value && handleChange( true, srNo )}
                                        onBlur={e => e?.target?.value && handleChange( false )}
                                    />
                                </Col>
                            </Row>
                        )}
                        {type === InputValuesObject.ckeditor && (
                            <Row>
                                <Col lg={24} xs={20}>
                                <CKEditorFieldSurvey
                                    name={id}
                                    rules={required ? rules : []}
                                    offset={2}
                                    onChange={e =>{withMarkdownFieldValue(id, e) ; handleChange(false, srNo)}}
                                    onBlur={e =>{withMarkdownFieldValue(id, e) ; handleChange(false)}}
                                />
                                </Col>
                            </Row>
                        )}
                        {showOk && type!==InputValuesObject.range&& type!==InputValuesObject.radio &&type!==InputValuesObject.date&&type!==InputValuesObject.month&&type!==InputValuesObject.time&&type!==InputValuesObject.week&&type!==InputValuesObject.color&&(
                            <button className='generic-survey-ok-button' disabled={load} onClick={e => nextStep( srNo, srNo )} {...props}>
                                Okay
                            </button>
                        )}
                    </div>
                )}
            </div>
        ) : null;
    };

    const label = (
        <div style={{display:'flex',flexDirection:'row',alignItems:'flex-start',gap:'0px'}}>
            <h4>{(idx+1)}.</h4>
            <ReactMarkdown style={{ marginLeft: '4px' }}>{matrixLabel}</ReactMarkdown>
        </div>
    );
    const renderLabel = (
        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", gap: "10px",width:"100%" ,paddingTop:"10px"}}>
            {matrix&&<span style={{color:"#DE4A4A"}}>*</span>}
            {label}
        </div>
    );
    console.log('render lanehchych', renderLabel)
    return matrix && matrix ? (
         <MatrixDiv label={renderLabel} className="generic-survey-question-container" style={{background:"rgba(244, 249, 253, 1)"}} currentIsMatrix={currentSection === srNo && currentIsMatrix}>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", gap: "10px" }}>
                    <h4 onClick={(e)=> handleQuestionClick(e)} dataKey={(idx+1)} className='generic-survey-question' style={{  cursor: "pointer",whiteSpace:"nowrap"}}>{renderLabel}</h4>
                    {/* {helpText && <Popover content={helpText}><img src={help_icon} alt='' style={{ cursor: "pointer", width: "25px" }} />
                    </Popover>} */}
                </div>
                {/* <SequenceNo>{srNo}</SequenceNo>
                <h3 style={{ fontWeight: 'bold' }}>{displayDataCheck( sectionLabel )}</h3> */}
            {currentSection === srNo && 
            <Row style={{ paddingBottom: '2vh', paddingTop: '2vh' }}>
            <Col lg={7} md={7} xs={0}></Col>
            <Col lg={17} md={20} xs={24}>
            <Row>
                {questionList[0]?.choiceSet?.edges?.map(({ node: choice }, key) => (
                <Col span={6} align="center">
                   <p className='survey-metrix-col-data'><ReactMarkdown> {choice?.label.replace(",","")}</ReactMarkdown></p>
                </Col>
                ))}
            </Row>
            </Col>
            </Row>}
            <Row style={{ marginBottom: '2vh' }}>
                {isPaneVisible && questionList?.length > 0 ? (
                    questionList
                        ?.sort( function ( a, b ) {
                            return parseInt( a.id ) - parseInt( b.id );
                        } )
                        .map( ( question, index ) => {
                            const { id, questionText, choiceSet, required } = question;
                            const rules = [ { required: true, message: 'An answer is required.' } ];
                            return (
                                <Col lg={{ span: 23, offset: 1 }} md={{ span: 23, offset: 1 }} xs={{ span: 24, offset: 0 }}>
                                    {isPaneVisible && (
                                        <SurveyMLSelectField
                                            otherOption={true}
                                            name={id}
                                            label={
                                                <Space style={{display:'flex',flexDirection:"row",justifyContent:"start",alignItems:'center',gap:"10px"}}>
                                                    {currentMatrixQuestion === index ? (
                                                        <CaretRightOutlined />
                                                    ) : (
                                                        <CaretRightOutlined style={{ opacity: 0 }} />
                                                    )}
                                                    {<p className='survey-metrix-col-data'><ReactMarkdown>{questionText}</ReactMarkdown></p>}
                                                </Space>
                                            }
                                            choices={choiceSet?.edges?.map( ( { node } ) => node ).map( c => ( { label: c.label, value: c.value } ) )}
                                            rules={required ? rules : []}
                                            onChange={e=>e?.target?.value && setShowOk(true)}
                                        />
                                    )}
                                </Col>
                            );
                        } )
                ) : (
                    <br />
                )}
            </Row>
            {showOk&&(
            <button className='generic-survey-ok-button' disabled={props.load} onClick={e => nextStep( srNo )} {...props}>
                Okay
            </button>
            )}
        </MatrixDiv>
        // <GenericMartx {...props} values={values}/>

    ) : questionList?.length > 0 ? (
        questionList?.map(RenderQuestion )
    ) : (
        <Empty />
    );
};

export default compose( withApollo )( GenericSurveyView );

const SequenceNo = styled.div`
//   border-radius: 50%;
  width: 28px;
  height: 28px;
  justify-content: center;
  align-items: center;
//   background: rgba(98, 98, 98, 1);
  color: white;
  display: inline-flex;
  margin-top:10px
`;

const MatrixDiv = styled.div`
  max-height: 75vh; /* ${props => ( props.currentIsMatrix ? '75vh' : '60vh' )}; */
  overflow-y: auto;
  width:100%;
`;

